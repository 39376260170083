@import "styles/global";

body {
  margin: 0;
  font-family: Inter;
}

.notification-container {
  position: fixed !important;
  top: var(--gap) !important;
  .notification-alert {

    @media (max-width: 1199px) {
      max-width: 50% !important;
    }

    @media (max-width: 991px) {
      max-width: calc(100% - calc(var(--gap) * 4)) !important;
    }
  }
}

.app-wrapper {
  background: var(--bg-page);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-variant-numeric: lining-nums;
}

a {
  color: var(--primary-500);
  font-size: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.unique-tabs-labels {
  flex-wrap: nowrap !important;
}

.unique-layout {
  background: var(--color-blue-grey-100);

  &__content {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    row-gap: var(--gap);
  }
  @media (max-width: 1024px) {
    footer {
      border-top: none !important;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    }
  }

  @media (min-width: 1920px) {
    main {
      width: 100%;
      margin: 32px auto 50px !important;
      padding: 0 48px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 1024px) {
    background: var(--color-blue-grey-100) !important;

    header {
      top: 0;
      position: sticky !important;
      z-index: 1000;
    }
  }

  @media (max-width: 767px) {
    footer {
      margin-top: 40px;
      padding: 16px !important;
      height: 100px !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: flex-start !important;
    }
  }
}

