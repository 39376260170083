:root {
  --white-color: #fff;
  --black-color: #000;
  --grey: #81858E;
  --grey-100: #EDEDEE;
  --grey-200: #DFE0E2;
  --grey-300: #D2D3D6;
  --grey-400: #C4C6CA;
  --grey-500: #81858E;
  --grey-600: #686C74;
  --dark: #040B1D;
  --blue-gray-200: #C9D0D7;
  --blue-gray-400: #9CAAB7;
  --blue-gray-500: #7F90A1;
  --blue-gray-600: #647789;
  --blue-grey-700: #5A6A7A;
  --blue-gray: #F5F6F7;
  --link-color: #0087D0;
  --link-light-color: #EBF8FF;
  --bg-page: #f9faff;
  --primary-100: #F5FCFF;
  --primary-200: #C7EBFF;
  --primary-300: #66CAFF;
  --primary-400: #15ADFF;
  --primary-500: #009CF0;
  --primary-600: #0085CC;
  --secondary-700: #02040B;
  --secondary-600: #030814;
  --secondary-500: #040B1D;
  --secondary-400: #091941;
  --secondary-300: #0B1F53;
  --secondary-200: #0E2665;
  --secondary-100: #102D77;
  --card-background: #fff;
  --header-background: #fff;
  --border-color: #D2D3D6;
  --unique-network: #009CF0;
  --quartz-primary: #FF4D6A;
  --opal-primary: #5942C8;
  --coral-100: #FFF1ED;
  --coral-700: #F73800;

  --gap: 16px;
  --bradius: 4px;
  --max-width: 1366px;
}
